const Translator = require('@andreasremdt/simple-translator').default;
const enUS = require('./i18n/en-US.json');
const ptBr = require('./i18n/pt-BR.json');

const langs = {
  "pt-BR": {
    code: 'pt-BR',
    showFlag: 'en-US'
  },
  "en-US": {
    code: 'en-US',
    showFlag: 'pt-BR'
  },
}

module.exports = function () {
  // You can optionally pass options
  const translator = new Translator({ persist: true, defaultLanguage: 'en-US', debug: true });
  const initialLang = Object.keys(langs).includes(translator.currentLanguage) ? langs[translator.currentLanguage] : langs['en-US'];

  // Add the langsuage to the translator and translate the page
  translator.add(langs['pt-BR'].code, ptBr).add(langs['en-US'].code, enUS
  ).translatePageTo(initialLang.code);

  const flagElement = "nav#navbar .nav-item #locale-flag";

  $(flagElement).html(`
  <img class="avatar avatar-xs"
    src="assets/locale/flags/${initialLang.showFlag}.svg"
    alt=${initialLang.showFlag}
  />`)

  $(flagElement).on("click", function () {
    $(this).html(() => {
      const newLang = this.firstElementChild.attributes.alt.nodeValue;
      const currentLang = Object.keys(langs).includes(newLang) ? langs[newLang] : langs['en-US'];

      translator.translatePageTo(currentLang.code);
      return `
    <img class="avatar avatar-xs"
      src="assets/locale/flags/${currentLang.showFlag}.svg"
      alt=${currentLang.showFlag}
    />
    `})
  })
}