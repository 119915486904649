
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

$(window).on('scroll', function () {
  if ((window.location.pathname === '/' || window.location.pathname === '/index.html' || window.location.pathname === '/contactus.html')) {
    $('[data-navbar="fixed"]').each(function () {
      setTimeout(() => {
        var tag = $(this);
        if (tag.hasClass('stick')) {
          tag.addClass('navbar-dark');
          tag.removeClass('navbar-light');
        }
        else {
          tag.removeClass('navbar-dark');
          tag.addClass('navbar-light');
        }
      }, 100)
    });
  }
});